<template>
    <n-list value="extras" text="選項分類" :fields="fields" :items="extras" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
                {
                    key: 'items',
                    label: '選項',
                    formatter: value => {
                        return `${value.length}項`
                    },
                    sortable: true,
                },
                {
                    key: 'minSelected',
                    label: '最少可選選項',
                    formatter: value => {
                        return `${value}項`
                    },
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'maxSelected',
                    label: '最多可選選項',
                    formatter: value => {
                        return `${value}項`
                    },
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'extras', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            extras: state => state.extras,
        })
    }
}
</script>